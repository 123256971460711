import React, { useEffect } from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import ArrowRight from "../components/svg/arrowRight"

function ThanksPage () {

    // For Google Ads conversion tracking event
    // useEffect(() => {
    //     window.dataLayer = window.dataLayer || [];
    //     window.dataLayer.push(["event", "conversion", {send_to: "AW-963878290/3r9nCMmBsKYDEJK7zssD"}]);
    //   }, []);


    return (
        <Layout>
            <Seo 
                title="Success"
            />

            <section role="main" className="bg-purple white">
            <div className="container">
                <div className="row flex flex-jc">
                    <div className="hero-content sub-hero">
                        <h1
                        className="hero-lead"
                        data-sal="slide-up" 
                        data-sal-easing="ease"
                        data-sal-delay="400"
                        data-sal-duration="400"
                        >We've received your enquiry and will get back to you very soon.</h1>
                        <p className="white">In the meantime click the button below to read helpful startup articles on our blog, follow us on <a href="http://twitter.com/Go_LaunchLab" title="Launch Lab on Twitter" className="pink">Twitter</a> or like us on <a href="https://www.facebook.com/launchlabau/" title="Launch Lab on Facebook" className="pink">Facebook</a>.</p>
                        <div className="btn-row flex flex-jc">
                            <Link to="/blog/" className="btn btn-lg btn-pink">
                                <span className="flex flex-ac">
                                        Startup blog
                                    <ArrowRight />
                                </span>
                            </Link>
                        </div>
                    </div>
                    </div>
                </div>
            </section>
            
        </Layout>
    );
}

export default ThanksPage