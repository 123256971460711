import React from "react"

const ArrowRight = () => (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1 9H17M17 9L9 1M17 9L9 17" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
</svg>


)

export default ArrowRight